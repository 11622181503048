

.asColorPicker-dropdown {
    max-width: 26rem;
}

.asColorPicker-trigger {
    border  : 0 none;
    height  : 100%;
    position: absolute;
    right: 0;
    top     : 0;
    width   : 2.1875rem;
}
[direction="rtl"] .asColorPicker-trigger{
	right: 99%;
	left: auto;
}
.asColorPicker-clear {
    display        : none;
    position       : absolute;
    right          : 1rem;
    text-decoration: none;
    top            : .5rem;
}



.daterangepicker {
    td.active {
        background-color: $primary;

        &:hover {
            background-color: $primary;
        }
    }

    button.applyBtn {
        background-color: $primary;
        border-color: $primary;
    }
}

.datepicker {

    &.datepicker-dropdown {
        background: $l-bg;
        border-radius: 1px;
        border: 1px solid $gallery;

        td.day, th.next, th.prev {
            height: 30px;
            width: 30px !important;
            padding: 0;
            text-align: center;
            font-weight: 300;
            border-radius: 50px;

            &:hover {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color: $white;
            }
        }

        th.datepicker-switch, th.next, th.prev {
            font-weight: 300;
            color: $heading;
        }

        th.dow {
            font-weight: 300;
        }
    }
    table {

        

        tr td.selected, tr td.active.active {
            @extend %gradient-9;
            box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
            border: 0;
        }

        tr {
            td.today {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color     : #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }

            td.today.disabled {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color     : #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }
        }
    }
}


.picker {
    &__select--month, &__select--year {
        height: 2.5em;
    }

    &__input {
        background-color: transparent !important;

        @at-root [data-theme-version="dark"] & {
            background-color: transparent !important;
            border: 1px solid $d-border;
        }
    }
}

.asColorPicker-wrap{
	.form-control{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

/*color picker css  */
.picker-data{
	.color-time-picker{
		.react-time-picker{
			width:100%;
		}
		.react-time-picker__wrapper{
			border:0;
			color:#7e7e7e;
			.react-time-picker__clear-button{
				display:none;
			}
			.react-time-picker__inputGroup{
				border:1px solid #b7b7b7;
				border-width:0 0 1px 0;
				padding: .35rem 0rem
			}
			.react-time-picker__inputGroup__input{
				color:#7e7e7e;
			}
			.react-time-picker__inputGroup__amPm{
				color:#7e7e7e;
				appearance: none;
			}
			.react-time-picker__clock-button{
				background:$primary;
				color: #b7b7b7;
				padding: .532rem .75rem;
				border-radius: 0 0.75rem 0.75rem 0;
				min-width: 50px;
				text-align: center;
				line-height: 1;
				svg{
					stroke: #fff;
					display:inline-block;
				}
			} 
		}
		.react-clock__face{
			background: #ededed;
			padding: 10px 10px;	
			border: 10px solid #ededed;
		}
		.react-clock__hand__body{
			background-color: #3b3363;
		}
		.react-time-picker__clock{
			box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
			border-radius:4px;
			border: 0 !important;
		}
		&.style-1{
			.react-time-picker__wrapper{
				.react-time-picker__clock-button{
					display: none;
					
				} 
			}
			
		}
		
	}
}